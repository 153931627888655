import { default as _91_46_46_46all_93xu44z2gGgNMeta } from "/usr/src/app/pages/[...all].vue?macro=true";
import { default as _91postId_93YqFfr2PgphMeta } from "/usr/src/app/pages/boards/[postId].vue?macro=true";
import { default as _91classifiedAdId_93x2J6v2hvBiMeta } from "/usr/src/app/pages/classifieds/[classifiedAdType]/[classifiedAdId].vue?macro=true";
import { default as _91userName_93XcUNRP4f1UMeta } from "/usr/src/app/pages/profiles/[userName].vue?macro=true";
import { default as sign_45in7sEcJLcQCLMeta } from "/usr/src/app/pages/sign-in.vue?macro=true";
import { default as signInkLasSjZISqMeta } from "/usr/src/app/pages/signIn.vue?macro=true";
import { default as singInx8FmAH9eLxMeta } from "/usr/src/app/pages/singIn.vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all(.*)*",
    meta: _91_46_46_46all_93xu44z2gGgNMeta || {},
    component: () => import("/usr/src/app/pages/[...all].vue")
  },
  {
    name: "boards-postId",
    path: "/boards/:postId()",
    component: () => import("/usr/src/app/pages/boards/[postId].vue")
  },
  {
    name: "classifieds-classifiedAdType-classifiedAdId",
    path: "/classifieds/:classifiedAdType()/:classifiedAdId()",
    meta: _91classifiedAdId_93x2J6v2hvBiMeta || {},
    component: () => import("/usr/src/app/pages/classifieds/[classifiedAdType]/[classifiedAdId].vue")
  },
  {
    name: "profiles-userName",
    path: "/profiles/:userName()",
    component: () => import("/usr/src/app/pages/profiles/[userName].vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/usr/src/app/pages/sign-in.vue")
  },
  {
    name: "signIn",
    path: "/signIn",
    component: () => import("/usr/src/app/pages/signIn.vue")
  },
  {
    name: "singIn",
    path: "/singIn",
    component: () => import("/usr/src/app/pages/singIn.vue")
  }
]