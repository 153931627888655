<template>
  <v-footer color="primary" app dark>
    <div class="tw-flex tw-flex-col tw-items-center tw-w-full">
      <GetTheApp class="tw-mb-2" />
      <div class="tw-p-2 tw-text-center">
        <VisitOurWebsiteBtn class="tw-mb-2" />
        <br />
        Copyright &copy; {{ new Date().getFullYear() }}
      </div>
    </div>
  </v-footer>
</template>
