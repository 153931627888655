import type { Pinia, PiniaPluginContext } from 'pinia'
import type { PaginatedResponse } from '~/types/api/base'
declare module 'pinia' {
  export interface PiniaCustomProperties {
    processPaginatedResponse: (
      responseData: PaginatedResponse<unknown>,
      key?: string,
    ) => void
    accumulatePaginatedResponse: (
      responseData: PaginatedResponse<unknown>,
      key?: string,
    ) => void
  }
}

const updateStore = ({
  storePath,
  data,
  accumalate = false,
}: {
  storePath: PiniaPluginContext['store']
  data: PaginatedResponse<unknown>
  accumalate?: boolean
}) => {
  if (accumalate) {
    storePath.items = [...storePath.items, ...data.items]
  } else {
    storePath.items = data.items
  }
  storePath.itemCount = data.meta.itemCount
  storePath.itemsPerPage = data.meta.itemsPerPage
  storePath.totalPages = data.meta.totalPages
  storePath.currentPage = data.meta.currentPage
  storePath.totalItems = data.meta.totalItems
}

export default defineNuxtPlugin(({ $pinia }) => {
  const pinia = $pinia as Pinia
  pinia.use((ctx: PiniaPluginContext) => {
    return {
      processPaginatedResponse: (
        responseData: PaginatedResponse<unknown>,
        key?: string,
      ) => {
        let storePath = ctx.store
        if (key) {
          storePath = ctx.store[key]
        }
        updateStore({ storePath, data: responseData })
      },

      accumulatePaginatedResponse: (
        responseData: PaginatedResponse<unknown>,
        key?: string,
      ) => {
        let storePath = ctx.store
        if (key) {
          storePath = ctx.store[key]
        }
        updateStore({ storePath, data: responseData, accumalate: true })
      },
    }
  })
})
