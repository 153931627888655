<template>
  <v-btn
    class="!tw-font-bold"
    color="primary"
    size="large"
    href="https://www.vestsocial.com"
  >
    VISIT OUR WEBSITE
  </v-btn>
</template>
