import type { AxiosInstance } from 'axios'
import type { Pinia } from 'pinia'
declare module 'pinia' {
  export interface PiniaCustomProperties {
    vestApi: AxiosInstance
  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  const { vestApi } = useVestApi()
  const pinia = $pinia as Pinia
  pinia.use(() => {
    return {
      vestApi,
    }
  })
})
