// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css'

import 'vuetify/styles'
import { createVuetify, type ThemeDefinition } from 'vuetify'

const vestLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#F7F7F7',
    primary: '#1A237E',
    secondary: '#C6FF00',
    error: '#FF3D00',
    info: '#8C9EFF',
    success: '#B1E204',
    warning: '#FF9800',
  },
}

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    defaults: {
      VCard: {
        VCardActions: {
          VBtn: {
            variant: 'flat',
          },
        },
      },
      VBtn: {
        variant: 'flat',
      },
      VTextField: {
        variant: 'outlined',
      },
      VSelect: {
        variant: 'outlined',
      },
      VCombobox: {
        variant: 'outlined',
      },
      VTextarea: {
        variant: 'outlined',
      },
      VAutocomplete: {
        variant: 'outlined',
      },
    },
    theme: {
      defaultTheme: 'vestLightTheme',
      themes: {
        vestLightTheme,
      },
    },
  })
  app.vueApp.use(vuetify)
})
