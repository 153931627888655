<template>
  <v-app>
    <NavBar />

    <v-main>
      <v-container class="fill-height">
        <v-row justify="center" align="center">
          <v-card>
            <v-card-title>
              {{ error?.statusCode }} - {{ error?.statusMessage }}
            </v-card-title>

            <v-card-subtitle class="tw-mb-3">
              Oops! Something went wrong
            </v-card-subtitle>

            <v-card-actions class="tw-justify-center">
              <v-btn
                class="!tw-font-bold"
                color="primary"
                :href="'https://www.vestsocial.com'"
              >
                GO TO VEST WEBSITE
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-container>
    </v-main>

    <Footer />
  </v-app>
</template>

<script lang="ts" setup>
  const error = useError()
</script>
