<template>
  <div class="tw-grid tw-grid-cols-2 tw-gap-3">
    <a
      class="tw-flex tw-justify-center"
      href="https://play.google.com/store/apps/details?id=com.jhl.vestapp"
    >
      <img src="@/assets/webp/DL_Google_Play.webp" />
    </a>
    <a
      class="tw-flex tw-justify-center"
      href="https://apps.apple.com/us/app/vest-social/id6502720330"
    >
      <img src="@/assets/webp/DL_App_Store.webp" />
    </a>
  </div>
</template>
