export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp()
  const router = nuxtApp.$router
  const sharedComponent = () => import('~/components/UserRoute.vue')

  const sharedRoutes = [
    '/home',
    '/boards/favorites',
    '/boards/post/new',
    '/classifieds/jobs',
    '/classifieds/jobs/new',
    '/classifieds/items',
    '/classifieds/items/new',
    '/classifieds/services',
    '/classifieds/services/new',
    '/conversations',
    '/notifications',
    '/profiles/me',
    '/settings',
  ]

  sharedRoutes.forEach((path) => {
    router.addRoute({ path, component: sharedComponent })
  })
})
