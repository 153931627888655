import axios from 'axios'
import type { AxiosInstance } from 'axios'

const vestApiObj: { vestApi?: AxiosInstance } = {}

export const useVestApi = (): { vestApi: AxiosInstance } => {
  if (vestApiObj.vestApi) {
    return {
      vestApi: vestApiObj.vestApi,
    }
  }

  // Create axios instance
  const runtimeConfig = useRuntimeConfig()
  const vestApi = axios.create({
    baseURL: runtimeConfig.public.apiBaseUrl as string,
  })

  return {
    vestApi,
  }
}
